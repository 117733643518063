import { Observable, of } from 'rxjs';
import { AuthProvider } from '@core/interfaces/auth-provider';
import { AuthService } from '@auth0/auth0-angular';

export class Auth0Provider implements AuthProvider {
  constructor(private auth0Service: AuthService) {}

  getToken(options?: import('@auth0/auth0-spa-js').GetTokenSilentlyOptions): Observable<string> {
    return this.auth0Service.getAccessTokenSilently(options);
  }

  getUser$(): Observable<import('@auth0/auth0-spa-js').User | null | undefined> {
    const user = this.auth0Service.user$;
    if (user) return this.auth0Service.user$;
    this.logout();
    return of(null);
  }

  getAccessTokenSilently(
    options?: import('@auth0/auth0-spa-js').GetTokenSilentlyOptions,
  ): Observable<string> {
    return this.auth0Service.getAccessTokenSilently(options);
  }

  isLoggedIn(): Observable<boolean> {
    return this.auth0Service.isAuthenticated$;
  }

  login(redirectPath: string): Observable<void> {
    return this.auth0Service.loginWithRedirect({
      authorizationParams: {
        prompt: 'none',
        redirectUri: redirectPath,
      },
    });
  }

  logout(): void {
    this.auth0Service.logout({ logoutParams: { returnTo: window.location.origin } });
  }

  getError$(): Observable<Error> {
    return this.auth0Service.error$;
  }
}
