import { Observable, of } from 'rxjs';
import { AuthProvider } from '@core/interfaces/auth-provider';
import { Injectable } from '@angular/core';
import { ConfigService } from '@core/keycloak/config.service';

@Injectable()
export class ApiKeyAuthProvider implements AuthProvider {
  constructor(private configService: ConfigService) {}

  getToken(): Observable<string> {
    return of(this.configService.apiKey);
  }

  getUser$(): Observable<unknown> {
    // TODO: fix in the proxy backend in the future
    return of({
      given_name: 'open',
      family_name: 'legacy',
      'https://ol.tenants/tenantId': 'fake',
      email: 'fake@openlegacy.com',
      'https://ol.tenants/company': 'openlegacy',
      'https://ol.tenants/profession': 'Developer',
      'https://ol.tenants/country': 'Israel',
      sub: 'fake',
    });
  }

  getAccessTokenSilently(): Observable<string> {
    return this.getToken();
  }

  isLoggedIn(): Observable<boolean> {
    return of(true);
  }

  login(): void {}

  logout(): void {}

  getError$(): Observable<unknown> {
    return of({});
  }
}
