import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface HubEnterpriseConfig {
  authDomain: string;
}

export interface HubProxyConfig {
  apiKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: HubEnterpriseConfig | HubProxyConfig | undefined;

  constructor(private http: HttpClient) {}

  async loadConfig(): Promise<HubEnterpriseConfig | HubProxyConfig> {
    const response = await this.http
      .get<HubEnterpriseConfig | HubProxyConfig>('/config')
      .toPromise();
    this.config = response;
    return response as HubEnterpriseConfig | HubProxyConfig;
  }

  get authDomain(): string | undefined {
    if (!this.config) {
      /* eslint-disable no-console */
      console.error('config could not be loaded from app config service.');
    }

    return (this.config as HubEnterpriseConfig)?.authDomain;
  }

  get apiKey(): string {
    return (this.config as HubProxyConfig)?.apiKey;
  }
}
