/* eslint-disable */

import { Injectable } from '@angular/core';
import { AuthProvider } from '@core/interfaces/auth-provider';
import { Observable } from 'rxjs';
import { KeycloakProvider } from '@core/providers/keycloack-auth.provider';
import { Auth0Provider } from '@core/providers/auth0-auth.provider';
import { ApiKeyAuthProvider } from '@core/providers/api-key-auth.provider';
import { MockIamProvider } from '@core/providers/mock-iam-auth.provider';

@Injectable({
  providedIn: 'root',
})
export class HubAuthService implements AuthProvider {
  private authProvider?: AuthProvider | null;

  constructor(
    private keycloakProvider: KeycloakProvider | null,
    private auth0Provider: Auth0Provider | null,
    private apiKeyAuthProvider: ApiKeyAuthProvider | null,
    private mockIamProvider: MockIamProvider | null,
  ) {
    if (keycloakProvider != null) {
      this.authProvider = this.keycloakProvider;
    } else if (apiKeyAuthProvider) {
      this.authProvider = this.apiKeyAuthProvider;
    } else if (auth0Provider != null) {
      this.authProvider = this.auth0Provider;
    } else if (mockIamProvider != null) {
      this.authProvider = this.mockIamProvider;
    } else {
      throw new Error('Auth provider must be supply once');
    }
  }

  getAccessTokenSilently(options?: any): Observable<string> {
    return this.authProvider!!.getAccessTokenSilently(options);
  }

  getToken(options?: any): Observable<string> {
    return this.authProvider!!.getToken(options);
  }

  getUser$(options?: boolean): Observable<any> {
    return this.authProvider!!.getUser$(options);
  }

  isLoggedIn(): Observable<boolean> {
    return this.authProvider!!.isLoggedIn();
  }

  login(redirectPath: string): void {
    this.authProvider!!.login(redirectPath);
  }

  logout(): void {
    this.authProvider!!.logout();
  }

  getError$(): Observable<unknown> {
    return this.authProvider!!.getError$();
  }
}
