import { from, Observable, of } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { AuthProvider } from '@core/interfaces/auth-provider';
import { KeycloakProfile } from 'keycloak-js';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeycloakProvider implements AuthProvider {
  constructor(private keycloakService: KeycloakService) {}

  getToken(): Observable<string> {
    return from(this.keycloakService.getToken());
  }

  getUser$(forcedReload?: boolean): Observable<KeycloakProfile> {
    return from(this.keycloakService.loadUserProfile(forcedReload));
  }

  getAccessTokenSilently(): Observable<string> {
    return from(this.keycloakService.getToken());
  }

  isLoggedIn(): Observable<boolean> {
    return of(this.keycloakService.isLoggedIn());
  }

  login(redirectPath: string): void {
    this.keycloakService
      .login({
        prompt: 'none',
        redirectUri: redirectPath,
      })
      .catch(() => {
        this.logout();
      });
  }

  logout(): void {
    this.keycloakService.logout(window.location.origin);
  }

  getError$(): Observable<unknown> {
    return this.keycloakService.keycloakEvents$;
  }
}
