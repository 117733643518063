import { Observable, of } from 'rxjs';
import { AuthProvider } from '@core/interfaces/auth-provider';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class MockIamProvider implements AuthProvider {
  private readonly mockIamPrefix = `${environment.MOCK_IAM_URL}`;

  private _token?: string;

  private _user?: string;

  constructor(private http: HttpClient) {}

  getToken(): Observable<string> {
    return this.getAccessTokenSilently();
  }

  getUser$(): Observable<unknown> {
    if (!this._user) {
      return this.http.get<unknown>(`${this.mockIamPrefix}/api/v1/user`).pipe(
        tap((user) => {
          this._user = user as unknown as string;
        }),
        take(1),
      );
    } else {
      return of(this._user);
    }
  }

  getAccessTokenSilently(): Observable<string> {
    if (!this._token) {
      return this.http
        .post<{ access_token: string; expires_in: number }>(`${this.mockIamPrefix}/oauth/token`, {})
        .pipe(
          map((res) => res.access_token),
          tap((token) => {
            this._token = token;
          }),
          take(1),
        );
    } else {
      return of(this._token);
    }
  }

  isLoggedIn(): Observable<boolean> {
    return of(this._token != undefined);
  }

  login(): void {}

  logout(): void {}

  getError$(): Observable<unknown> {
    return of({});
  }
}
